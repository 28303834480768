import React from 'react';
import PropTypes from 'prop-types';
import SEO from '../components/molecules/Seo';
import PageLayout from '../components/layouts/Main/PageLayout';
import { NetworkSchema } from '../schema/network';
import { FourOFour } from '../components/molecules/fourOfour';

const Custom404Page = ({ network, advancedDesign }) => (
  <PageLayout network={network} advancedDesign={advancedDesign}>
    <SEO network={network} title="Oops! We can't find that page" />
    <FourOFour network={network} />
  </PageLayout>
);

Custom404Page.propTypes = {
  network: NetworkSchema,
  advancedDesign: PropTypes.object,
};

Custom404Page.defaultProps = {
  network: {},
  advancedDesign: {},
};

export default Custom404Page;
